import { NvError } from '@northvolt/cloud-error-handling'

/**
 * the type of response.errors[number] from our graphql server
 */
export type GqlErrorResponse = { message: string }

export class NvGqlResponseError extends NvError {
  public name = 'NvGqlResponseError'
  // TODO once we start capturing GQL errors server-side we want to switch this to false:
  public shouldReportError = true
  public gqlError: GqlErrorResponse

  public getReportExtra = () => {
    return {
      graphQLError: this.gqlError,
    }
  }

  /**
   * @param gqlError should match graphql response.errors[number], should NOT be a JS Error
   */
  constructor(gqlError: GqlErrorResponse) {
    super(gqlError.message, null)
    this.gqlError = gqlError
  }
}

export type NvGqlNetworkErrorType = 'timeout' | 'unknown' | 'invalid-body' | 'status-code'

export class NvGqlNetworkError extends NvError {
  public name = 'NvGqlNetworkError'
  public shouldReportError = true
  public getReportExtra = () => {
    return {
      type: this.type,
      statusCode: this.statusCode,
    }
  }
  public type: NvGqlNetworkErrorType = 'unknown'
  public statusCode: number | null = null

  constructor(type: number | Exclude<NvGqlNetworkErrorType, 'status-code'>, cause: unknown) {
    let message = `${type}`
    switch (type) {
      case 400:
        message = '400 Malformed client request'
        break
      case 401:
        message = '401 User unauthorized'
        break
      case 500:
        message = '500 Internal Server Error'
        break
      case 'timeout':
        message = 'Request Timeout'
        break
      case 'invalid-body':
        message = 'Invalid response body'
        break
      case 'unknown':
        message = 'Unknown Error'
        break
    }
    super(message, cause)
    if (typeof type === 'number') {
      this.type = 'status-code'
      this.statusCode = type
    } else {
      this.type = type
    }
  }
}
