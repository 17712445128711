import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons'
import { Float } from '@headlessui-float/react'
import { Combobox } from '@headlessui/react'
import clsx from 'clsx'
import { MutableRefObject, useCallback, useRef, useState } from 'react'

import { BoxElementAffixProp, BoxInputElement } from '../Box'
import { Options, OptionsProps } from '../Options'
import getStandardTransitionProps from '../StandardTransition/getStandardTransitionProps'
import { InputState } from '../types'
import { FormFieldWrapper, UseFormFieldProps } from '../useForm'
import { Size, useSizeScreen } from '../useSizeScreen'

export type InputProps = {
  label?: string
  disabled?: boolean
  type?: string
  placeholder?: string
  size?: Size
  className?: string
  inline?: boolean
  tabIndex?: number
  required?: boolean
  state?: InputState

  /** if absent will invoke onChange instead */
  onAutocompleteSelection?: (newValue: string) => void
  prefix?: BoxElementAffixProp
  suffix?: BoxElementAffixProp
  autocomplete?: OptionsProps['list']
  autoFocus?: boolean
  noResultsMessage?: OptionsProps['noResultsMessage']
  inputRef?: MutableRefObject<HTMLInputElement | null>
  testId?: string
} & UseFormFieldProps<string>

export function Input({
  name,
  label,
  disabled,
  onChange,
  onBlur,
  required,
  error,
  placeholder,
  className,
  inline,
  tabIndex,
  size,
  type,
  value,
  onAutocompleteSelection,
  state,
  prefix,
  suffix,
  autocomplete,
  autoFocus,
  noResultsMessage = null,
  inputRef,
  testId,
}: InputProps) {
  const [autocompleteQuery, setAutocompleteQuery] = useState<string | null>(null)
  const localInputRef = useRef<HTMLInputElement | null>(null)
  const actualRef = inputRef ?? localInputRef

  const onSelection = useCallback(
    (newValue: string) => {
      setAutocompleteQuery(null)
      onBlur?.()
      if (onAutocompleteSelection != null) {
        onAutocompleteSelection(newValue)
      } else {
        onChange?.(newValue)
      }
    },
    [onBlur, onChange, onAutocompleteSelection],
  )

  const sizeScreen = useSizeScreen(size)

  return (
    <Combobox
      aria-label={label}
      aria-required={required}
      as="div"
      className={clsx('relative', {
        'w-full': !inline,
      })}
      disabled={disabled || state === 'loading'}
      name={name}
      // triggered when user keyboard-select an item from the dropdown
      onChange={onSelection}
      value={value}
    >
      {({ open }) => {
        return (
          <FormFieldWrapper
            as="Combobox"
            className={className}
            error={error}
            label={label}
            required={required ?? null}
            size={sizeScreen}
          >
            <Float
              {...getStandardTransitionProps()}
              adaptiveWidth
              as="div"
              className="relative w-full"
              offset={1}
              placement="bottom"
              portal
              show={open && autocompleteQuery !== null}
            >
              <BoxInputElement
                as="Combobox"
                autoFocus={autoFocus}
                disabled={disabled ?? false}
                error={error ?? null}
                inputRef={actualRef}
                name={name}
                onBlur={onBlur ?? null}
                onChange={(ev) => {
                  if (autocomplete != null) {
                    const autocompleteQuery = ev.target.value
                    setAutocompleteQuery(autocompleteQuery === '' ? null : autocompleteQuery)
                  }
                  onChange?.(ev.target.value)
                }}
                placeholder={placeholder ?? null}
                prefix={
                  prefix ??
                  (autocomplete != null
                    ? {
                        icon: faMagnifyingGlass,
                        onClick: () => {
                          actualRef.current?.focus()
                        },
                      }
                    : null)
                }
                size={sizeScreen}
                state={state ?? null}
                suffix={suffix}
                tabIndex={tabIndex ?? null}
                type={type}
                value={value ?? ''}
                testId={testId}
              />

              <Options
                as="Combobox"
                list={autocomplete ?? []}
                noResultsMessage={noResultsMessage}
                searchString={autocompleteQuery}
                size={sizeScreen}
                value={value}
              />
            </Float>
          </FormFieldWrapper>
        )
      }}
    </Combobox>
  )
}
