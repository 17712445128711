import { captureException } from '@sentry/core'
import jwtDecode from 'jwt-decode'

import { ReactAppConfig, config } from '@northvolt/cloud-config'

import { AuthUser, JwtValue, RolesEnum } from '../types'

import { eraseCookie, getCookie, getJwtFromCookie, setCookie } from './cookies'

const authConfig: NonNullable<ReactAppConfig['REACT_APP_AUTHENTICATION']> =
  config.REACT_APP_AUTHENTICATION ?? {
    newAuthFlow: true,
    cognitoUserPoolWebClientId: '',
    cookieDomain: '',
    cookiePrefix: '',
    loginUri: '',
    loginRedirectUri: '',
    logoutUri: '',
    oauthDomain: '',
    refreshTokenValidityDays: 30,
  }

export class Auth {
  constructor() {
    if (typeof window === 'undefined') {
      throw new Error('Auth is currently not possible to use in servers :)')
    }
  }

  logout() {
    eraseCookie(`${authConfig.cookiePrefix}refresh`, authConfig.cookieDomain)
    eraseCookie(`${authConfig.cookiePrefix}id`, authConfig.cookieDomain)
    eraseCookie(`${authConfig.cookiePrefix}access`, authConfig.cookieDomain)
    window.location.replace(authConfig.logoutUri)
  }

  redirectToLogin() {
    if (authConfig.loginUri === '') {
      return
    }
    if (authConfig.newAuthFlow) {
      // after login we can not redirect the user back to the same page he was in
      // this limitation could be removed with a dedicated login page or doing the redirect
      // through a backend API, but that would complicate things quite a bit and make this
      // module coupled with the backend redirect logic
      // in practice users only get redirected to login when their refresh_token expires which
      // happens once every 30 days
      window.location.replace(`${authConfig.loginUri}`)
    } else {
      window.location.replace(`${authConfig.loginUri}${encodeURIComponent(window.location.href)}`)
    }
  }

  /**
   * Try to get a valid JWT Access token from cookies.
   * Returns null if token doesn't exist or is invalid.
   * Doesn't refresh the token if it is expired
   */
  static getTokenSync(): JwtValue | null {
    const accessToken = getJwtFromCookie(`${authConfig.cookiePrefix}access`)
    const idToken = getJwtFromCookie(`${authConfig.cookiePrefix}id`)

    if (accessToken == null || idToken == null) {
      return null
    }

    return idToken
  }

  static getUserData(idToken: string | null): AuthUser | null {
    try {
      const idData = jwtDecode(idToken ?? '') as any
      const validRoles = Object.values(RolesEnum)
      return {
        email: idData.email,
        firstName: idData.given_name,
        fullName: `${idData.given_name} ${idData.family_name}`,
        lastName: idData.family_name,
        roles:
          ((idData['custom:roles'] as string | undefined)
            ?.split(' ')
            .map((role) => {
              const roleName = role.trim()
              if (validRoles.includes(roleName as any)) {
                return roleName as RolesEnum
              }
              return null
            })
            .filter((role) => {
              return role != null
            }) as RolesEnum[]) ?? [],
        tenant: idData['custom:tenant'],
        jobTitle: idData['custom:jobtitle'],
      }
    } catch (error) {
      return null
    }
  }

  /**
   * Try to get a valid JWT Access token from cookies.
   * If it is not valid, try to refresh it.
   * Returns null if token doesn't exist or is invalid.
   * Refreshes the token if expired
   */
  async getToken(): Promise<JwtValue | null> {
    const idToken = Auth.getTokenSync()
    if (idToken == null || Date.now() > idToken.expires.getTime() - 5 * 60 * 1000) {
      // if idToken is absent, expired or about to expire, refresh the idToken
      try {
        await this._fetchNewCredentials()
      } catch (err) {
        // could not get new token, no internet or bad refresh token?
        return null
      }
      return this.getToken() // Try again!
    }

    return idToken
  }

  private _fetchNewCredentialsPromise: Promise<void> | null = null
  private async _fetchNewCredentials() {
    // prevents multiple calls to fetchCredentials from triggering while waiting for HTTP responses
    if (this._fetchNewCredentialsPromise == null) {
      this._fetchNewCredentialsPromise = this._doFetchNewCredentials()
      this._fetchNewCredentialsPromise.finally(() => {
        this._fetchNewCredentialsPromise = null
      })
    }
    return this._fetchNewCredentialsPromise
  }

  private async _doFetchNewCredentials() {
    let refreshToken = getCookie(`${authConfig.cookiePrefix}refresh`)
    let accessToken: string | null = null
    let idToken: string | null = null
    let oauthError: string | null = null

    const cognitoCode = new URLSearchParams(window.location.search).get('code')
    if (refreshToken == null && authConfig.newAuthFlow && cognitoCode != null) {
      // the difference between the newAuthFlow and the old one is that this package now handles
      // obtaining the refresh_token from cognito directly, instead of relying on an external
      // application to set the refresh_token as a cookie on this applications domain

      // see:
      // https://docs.aws.amazon.com/cognito/latest/developerguide/token-endpoint.html
      const response = await postFormData(authConfig.oauthDomain, 'oauth2/token', {
        client_id: authConfig.cognitoUserPoolWebClientId,
        code: cognitoCode,
        grant_type: 'authorization_code',
        // gets id_token as well
        scope: 'openid',
        redirect_uri: authConfig.loginRedirectUri,
      })
      // remove ?code from URL
      window.history.replaceState({}, '', window.location.pathname)
      refreshToken = (response.refresh_token ?? null) as string | null
      accessToken = response.access_token
      idToken = response.id_token
      oauthError = response.error

      if (refreshToken != null) {
        setCookie(
          `${authConfig.cookiePrefix}refresh`,
          refreshToken,
          new Date(Date.now() + authConfig.refreshTokenValidityDays * 24 * 60 * 60 * 1000),
          authConfig.cookieDomain,
        )
      }
    }

    if (oauthError != null || refreshToken == null) {
      if (oauthError != null) {
        // this error was returned from the pre-token-generation lambda, it shouldn't be
        // possible under normal circunstances
        captureException(new Error(`[oautherror]: ${oauthError}`))
        // TODO when we have a proper landing page we can show the error there
        alert(oauthError)
      }
      // refresh token is expired or absent
      this.redirectToLogin()
      throw new Error('refreshTokenString == null. User is probably logged out')
    }

    if (idToken == null || accessToken == null) {
      const response = await postFormData(authConfig.oauthDomain, 'oauth2/token', {
        client_id: authConfig.cognitoUserPoolWebClientId,
        grant_type: 'refresh_token',
        refresh_token: refreshToken,
      })

      accessToken = response.access_token
      idToken = response.id_token
      oauthError = response.error

      if (oauthError != null || accessToken == null || idToken == null) {
        // if the request actually succeeds but we get an error it means the refresh token
        // was not valid
        // in those cases the response will be {"error":"invalid_grant"}

        // just in case the login app is not doing its job properly
        // we erase the refreshToken cookie to prevent an infinite redirect loop
        const err = new Error(
          oauthError ?? 'missing payload from refreshToken, login is no longer valid',
        )
        captureException(err)
        eraseCookie(`${authConfig.cookiePrefix}refresh`, authConfig.cookieDomain)
        this.redirectToLogin()
        throw err
      }
    }

    const getExpirationDate = (tokenString: string) => {
      const decoded = jwtDecode(tokenString) as any
      return new Date(decoded.exp * 1000)
    }

    setCookie(
      `${authConfig.cookiePrefix}access`,
      accessToken,
      getExpirationDate(accessToken),
      authConfig.cookieDomain,
    )
    setCookie(
      `${authConfig.cookiePrefix}id`,
      idToken,
      getExpirationDate(idToken),
      authConfig.cookieDomain,
    )
  }
}

async function postFormData(domain: string, endpoint: string, payload: Record<string, string>) {
  const formData: any = Object.keys(payload)
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(payload[key])}`
    })
    .join('&')

  const response = await fetch(`https://${domain}/${endpoint}`, {
    body: formData,
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
    },
    method: 'POST',
  })
  return response.json()
}
