import {
  faBoxes,
  faCopy,
  faDatabase,
  faHistory,
  faPaste,
  faSignOutAlt,
  faSliders,
  faTrash,
  faUserCog,
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import clsx from 'clsx'
import { ComponentProps, useMemo } from 'react'

import { config } from '@northvolt/cloud-config'
import { Footer, Header, HeaderProps } from '@northvolt/layout'
import { SystemsMigrationNotification } from '@northvolt/systems-migration-notification'

import { AuthenticationProvider, useAuthenticationContext } from './AuthenticationProvider'
import { RolesEnum } from './types'

type AuthenticationHeaderProps = Omit<HeaderProps, 'environment' | 'user' | 'version' | 'apps'>

type AuthenticationProviderProps = ComponentProps<typeof AuthenticationProvider>

type AuthenticationWrapperProviderProps = AuthenticationProviderProps & {
  header: null | Omit<AuthenticationHeaderProps, 'config'>
  footer: null | ComponentProps<typeof Footer>
}

export function AuthenticationWrapperProvider({
  loading,
  children,
  mock,
  header,
  footer,
}: AuthenticationWrapperProviderProps) {
  return (
    <AuthenticationProvider loading={loading} mock={mock}>
      {header != null && <AuthenticationHeader {...header} />}
      <div
        className={clsx({
          // prevents the footer from being in the middle of the screen when
          // a page has no content in the middle (for example while loading the list
          // of devices in the inventory)
          'min-h-[calc(100vh-70px)]':
            header !== null && header?.displayMode !== 'absolute' && footer !== null,
          'min-h-[calc(100vh)]':
            (header == null || header?.displayMode === 'absolute') && footer !== null,
        })}
      >
        {children}
      </div>
      {footer != null && <Footer {...footer} />}
    </AuthenticationProvider>
  )
}

export function AuthenticationHeader({ menu, ...headerProps }: AuthenticationHeaderProps) {
  const {
    user,
    copyTokenToClipboard,
    overrideTokenFromClipboard,
    clearOverrideToken,
    tokenOverrideStatus,
    logout,
  } = useAuthenticationContext()

  const menuWithAuth = useMemo(() => {
    const menuItems = [...(menu ?? [])]
    menuItems.push({
      type: 'section',
      title: 'General',
    })

    menuItems.push({
      type: 'link',
      title: 'Releases',
      icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faHistory} />,
      externalLink: true,
      link: `${config.REACT_APP_BASE_PORTAL_URL}/software/`,
    })
    menuItems.push({
      type: 'item',
      title: 'Logout',
      icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faSignOutAlt} />,
      onClick: logout,
    })

    if (
      user.roles.some(
        (role: RolesEnum) =>
          role === RolesEnum.BATTERY_DIAGNOSTICS_ADMIN || role === RolesEnum.ROOT,
      )
    ) {
      menuItems.push({
        type: 'link',
        title: 'Parameters',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faSliders} />,
        externalLink: true,
        link: `${config.REACT_APP_BASE_BATTERY_SYSTEM_URL}/parameters/`,
      })
    }
    if (user.tenant === 'northvolt') {
      menuItems.push({
        type: 'section',
        title: 'Developer utilities',
      })
    }
    if (
      config.REACT_APP_ENVIRONMENT === 'localhost' ||
      user.tenant === 'northvolt' ||
      user.tenant === 'bkk'
    ) {
      menuItems.push({
        type: 'item',
        title: 'Copy Token',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faCopy} />,
        onClick: copyTokenToClipboard,
      })
    }

    if (config.REACT_APP_ENVIRONMENT === 'localhost') {
      menuItems.push({
        type: 'item',
        title: 'Paste Token',
        icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faPaste} />,
        onClick: overrideTokenFromClipboard,
      })
      if (tokenOverrideStatus) {
        menuItems.push({
          type: 'item',
          title: 'Clear Token',
          icon: <FontAwesomeIcon className="mt-1 h-4 w-4 text-gray-700" icon={faTrash} />,
          onClick: clearOverrideToken,
        })
      }
    }

    return menuItems
  }, [
    user.roles,
    user.tenant,
    menu,
    copyTokenToClipboard,
    overrideTokenFromClipboard,
    clearOverrideToken,
    tokenOverrideStatus,
    logout,
  ])

  const userWithMappedRoles = useMemo(() => {
    const rolesMap = {
      [RolesEnum.ROOT]: 'nv-admin',
      [RolesEnum.BATTERY_DIAGNOSTICS_ADMIN]: 'admin',
      [RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION]: 'production',
      [RolesEnum.BATTERY_DIAGNOSTICS_WRITER]: 'technician',
      [RolesEnum.BATTERY_DIAGNOSTICS_READER]: 'viewer',
    }

    return {
      ...user,
      extra: user.roles.map((role) => rolesMap[role]).join(', '),
    }
  }, [user])

  const apps = useMemo(() => {
    // Logic is really old and should be refactored
    const portalIsEnabled =
      user.tenant !== 'epiroc'
        ? true
        : user.roles?.includes(RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION) ||
          user.roles?.includes(RolesEnum.BATTERY_DIAGNOSTICS_ADMIN)

    const apps: HeaderProps['apps'] = []

    apps.push({
      title: 'Inventory',
      url: config.REACT_APP_BASE_INVENTORY_URL,
      icon: faBoxes,
    })

    if (portalIsEnabled) {
      apps.push({
        title: 'Software & Docs',
        url: config.REACT_APP_BASE_PORTAL_URL,
        icon: faUserCog,
      })
    }

    if (user.tenant === 'northvolt') {
      apps.push({
        title: 'Datalake Docs',
        url: config.REACT_APP_BASE_DATALAKE_DOCS_URL,
        icon: faDatabase,
      })
    }

    return apps
  }, [user])

  return (
    <Header
      {...headerProps}
      environment={config.REACT_APP_ENVIRONMENT}
      menu={menuWithAuth}
      user={userWithMappedRoles}
      version={config.REACT_APP_VERSION}
      extraTitleContent={<SystemsMigrationNotification />}
      apps={apps}
    />
  )
}
