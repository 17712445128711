import { useCallback } from 'react'

import { useAuthenticationContext } from '@northvolt/cloud-auth'
import {
  Dropdown,
  Input,
  InputMultiselect,
  UseFormErrors,
  Validate,
  useForm,
} from '@northvolt/form'
import {
  EditCoreQuery,
  SetCoreDisplayNameMutation,
  SetCoreDisplayNameMutationVariables,
  SetCoreMacAddressMutation,
  SetCoreMacAddressMutationVariables,
  SetCoreTagsMutation,
  SetCoreTagsMutationVariables,
  SetCoreTenantMutation,
  SetCoreTenantMutationVariables,
  gql,
  useGql,
  useGqlMutation,
} from '@northvolt/gql'

import { useEntityRelationships } from './useEntityRelationships'

const editCoreQuery = gql`
  query editCore($id: ID!) {
    batterySystem(id: $id) {
      id
      displayName
      tags
      meta {
        tenant
      }
      computers {
        id
      }
      parentSystem {
        id
        displayName
      }
    }
  }
`

const setCoreDisplayNameMutation = gql`
  mutation setCoreDisplayName($id: ID!, $displayName: String!) {
    setCoreDisplayName(id: $id, displayName: $displayName) {
      success
    }
  }
`

const setCoreTenantMutation = gql`
  mutation setCoreTenant($id: ID!, $tenant: ID!) {
    setCoreTenant(id: $id, tenant: $tenant) {
      success
    }
  }
`

const setCoreMacAddressMutation = gql`
  mutation setCoreMacAddress($id: ID!, $macAddress: String!) {
    setCoreMacAddress(id: $id, macAddress: $macAddress) {
      success
    }
  }
`

const setCoreTagsMutation = gql`
  mutation setCoreTags($id: ID!, $tags: [String!]!) {
    setCoreTags(id: $id, tags: $tags) {
      success
    }
  }
`

type EditCoreData = {
  displayName: string
  tenant: { value: string; label: string } | null
  macAddress: string
  tags: string[]
}

export function EditCore({ id }: { id: string }) {
  const {
    user: { tenant: userTenant },
  } = useAuthenticationContext()

  const { tenants, tags } = useEntityRelationships('core')

  const {
    data: { batterySystem },
    mutate,
  } = useGql<EditCoreQuery>(editCoreQuery, { id })

  const { mutation: setDisplayName, result: displayNameResult } = useGqlMutation<
    SetCoreDisplayNameMutation,
    SetCoreDisplayNameMutationVariables
  >(setCoreDisplayNameMutation)

  const { mutation: setTenant, result: tenantResult } = useGqlMutation<
    SetCoreTenantMutation,
    SetCoreTenantMutationVariables
  >(setCoreTenantMutation)

  const { mutation: setMacAddress, result: macAddressResult } = useGqlMutation<
    SetCoreMacAddressMutation,
    SetCoreMacAddressMutationVariables
  >(setCoreMacAddressMutation)

  const { mutation: setTags, result: tagsResult } = useGqlMutation<
    SetCoreTagsMutation,
    SetCoreTagsMutationVariables
  >(setCoreTagsMutation)

  const setDisplayNameOnBlur = useCallback(
    async (value: string) => {
      await setDisplayName({
        id,
        displayName: value,
      })
      mutate()
    },
    [id, setDisplayName, mutate],
  )

  const setTenantOnSelected = useCallback(
    async (value: string) => {
      await setTenant({
        id,
        tenant: value,
      })
      mutate()
    },
    [id, setTenant, mutate],
  )

  const setMacAddressOnBlur = useCallback(
    async (value: string) => {
      await setMacAddress({
        id,
        macAddress: value,
      })
      mutate()
    },
    [id, setMacAddress, mutate],
  )

  const setTagsOnAdded = useCallback(
    async (value: string | readonly string[]) => {
      await setTags({
        id,
        tags: value,
      })
      mutate()
    },
    [id, setTags, mutate],
  )

  const { getFieldPropsByName, ...state } = useForm<EditCoreData>({
    initialValues: {
      tenant: {
        value: batterySystem.meta.tenant,
        label: batterySystem.meta.tenant,
      },
      displayName: batterySystem.displayName ?? '',
      macAddress: batterySystem.computers?.[0]?.id ?? '',
      tags: batterySystem.tags as string[],
    },
    onValidate: (values) => {
      const errors: UseFormErrors<EditCoreData> = {}
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name.'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a tenant.'
      }
      if (!Validate.testMacAddress(values.macAddress)) {
        errors.macAddress = Validate.macAddressAllowedFormats
      }
      return errors
    },
    onChange: (fieldName, formData, formErrors) => {
      if (fieldName === 'tenant' && formData.tenant != null && formErrors.tenant == null) {
        setTenantOnSelected(formData.tenant?.value)
      }
      if (fieldName === 'tags' && formErrors.tags == null) {
        setTagsOnAdded(formData.tags)
      }
    },
    onBlur: (fieldName, formData, formErrors) => {
      if (
        fieldName === 'displayName' &&
        formData.displayName != null &&
        formErrors.displayName == null
      ) {
        setDisplayNameOnBlur(formData.displayName)
      }
      if (
        fieldName === 'macAddress' &&
        formData.macAddress != null &&
        formErrors.macAddress == null
      ) {
        setMacAddressOnBlur(Validate.getMacAddressFromInput(formData.macAddress))
      }
    },
  })

  return (
    <>
      <span className="text-lg text-black pr-3 text-left">
        Pack: {batterySystem.parentSystem?.id ?? 'N/A'}
      </span>

      {userTenant === 'northvolt' && (
        <>
          <Input
            className="mb-2"
            label="Display Name"
            state={displayNameResult?.state}
            tabIndex={1}
            {...getFieldPropsByName('displayName')}
            error={
              displayNameResult?.state === 'error'
                ? displayNameResult?.errors[0].message
                : state.errors.displayName
            }
          />
          <div className="mb-2">
            <Dropdown
              label="Tenant"
              list={tenants}
              placeholder="Select tenant"
              state={tenantResult?.state}
              tabIndex={2}
              {...getFieldPropsByName('tenant')}
              error={
                tenantResult?.state === 'error'
                  ? tenantResult?.errors[0].message
                  : state.errors.tenant
              }
            />
          </div>
        </>
      )}

      <Input
        className="mb-2"
        label="MAC Address"
        state={macAddressResult?.state}
        tabIndex={3}
        {...getFieldPropsByName('macAddress')}
        error={
          macAddressResult?.state === 'error'
            ? macAddressResult?.errors[0].message
            : state.errors.macAddress
        }
      />
      <InputMultiselect
        autocomplete={tags}
        className="mb-2"
        label="Tags"
        state={tagsResult?.state}
        tabIndex={4}
        {...getFieldPropsByName('tags')}
        error={tagsResult?.state === 'error' ? tagsResult?.errors[0].message : state.errors.tags}
      />
    </>
  )
}
