import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'

import { AuthenticationWrapperProvider } from '@northvolt/cloud-auth'
import { ErrorHandlingContextProvider, initErrorReporting } from '@northvolt/cloud-error-handling'

import { App } from './App'
import { Loader } from './components/Loader'
import './global.css'

function Loading() {
  return <Loader />
}

function Wrapper() {
  return (
    <AuthenticationWrapperProvider
      footer={{}}
      header={{
        appName: 'Inventory',
        title: 'Systems',
        displayMode: 'absolute',
        transparent: true,
        align: 'responsive',
      }}
      loading={<Loading />}
    >
      <App />
    </AuthenticationWrapperProvider>
  )
}

initErrorReporting({
  projectName: 'inventory',
  projectType: 'cb-cloud-ui',
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(
  <StrictMode>
    <ErrorHandlingContextProvider>
      <Wrapper />
    </ErrorHandlingContextProvider>
  </StrictMode>,
)
