export enum RolesEnum {
  /**
   * read access, tenant: any
   */
  BATTERY_DIAGNOSTICS_READER = 'orb-battery-diagnostics-reader',

  /**
   * write access, tenant: any
   */
  BATTERY_DIAGNOSTICS_WRITER = 'orb-battery-diagnostics-writer',

  /**
   * production access, tenant: Epiroc only
   */
  BATTERY_DIAGNOSTICS_PRODUCTION = 'orb-battery-diagnostics-production',

  /**
   * admin access, tenant: Epiroc only
   */
  BATTERY_DIAGNOSTICS_ADMIN = 'orb-battery-diagnostics-admin',

  /**
   * root admin access, tenant: Northvolt only
   */
  ROOT = 'root', // root admin access, tenant: Northvolt
}

export type AuthUser = {
  email: string
  firstName: string
  fullName: string
  lastName: string
  roles: RolesEnum[]
  tenant: string
  avatar?: string
  jobTitle: string
}

export type JwtValue = {
  readonly tokenString: string
  readonly decodedContents: any
  readonly expires: Date
}
