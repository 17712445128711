import { Combobox } from '@headlessui/react'
import clsx from 'clsx'
import { ForwardedRef, forwardRef } from 'react'

import { getTestId } from '@northvolt/test-utils'

import { useSizeScreen } from '../useSizeScreen'

import { BoxElementContainer } from './BoxElementContainer'
import { getBoxElementTextSizeClassName } from './getBoxElementTextSizeClassName'
import { BoxInputProps } from './types'

/**
 * Renders the main element of the InputBox
 * Heights are standardized to match this component through the use of common font-size and vertical
 * padding.
 * See getBoxElementTextSizeClassName()
 */
function BoxInputElementInner(
  {
    as,
    autoFocus,
    disabled,
    error,
    inputRef,
    min,
    name,
    onBlur,
    onChange,
    onFocus,
    outline,
    placeholder,
    prefix,
    size,
    state,
    step,
    suffix,
    tabIndex,
    type,
    value,
    ariaLabel,
    testId,
  }: BoxInputProps,
  forwardedRef?: ForwardedRef<HTMLDivElement>,
) {
  const sizeScreen = useSizeScreen(size)
  const loadingOrDisabled = state === 'loading' || disabled

  const className = clsx(
    // rounded here is necessary to prevent the input from overflowing into the
    // border radius of its parent
    'peer outline-none border-none rounded flex flex-1 w-full',
    getBoxElementTextSizeClassName(sizeScreen),
    {
      'text-gray-400': value == null, // placeholder is visible
      'text-gray-600': value != null && loadingOrDisabled,
      'text-black': value != null && !loadingOrDisabled,
      'cursor-not-allowed': loadingOrDisabled,
      'pl-4': prefix == null,
      'pr-4': suffix == null,
    },
  )

  const Input = as === 'Combobox' ? Combobox.Input : 'input'

  return (
    <BoxElementContainer
      as="Combobox"
      disabled={loadingOrDisabled ?? false}
      error={error}
      outline={outline}
      prefix={prefix}
      ref={forwardedRef}
      size={size}
      state={state}
      suffix={suffix}
    >
      <Input
        aria-label={ariaLabel}
        autoComplete="off"
        autoFocus={autoFocus}
        className={className}
        disabled={loadingOrDisabled ?? false}
        min={min ?? undefined}
        name={name}
        onBlur={onBlur ?? undefined}
        onChange={onChange}
        onFocus={onFocus ?? undefined}
        placeholder={placeholder ?? undefined}
        readOnly={loadingOrDisabled ?? undefined}
        ref={inputRef}
        step={step ?? undefined}
        tabIndex={tabIndex ?? undefined}
        type={type}
        value={value}
        {...getTestId(testId)}
      />
    </BoxElementContainer>
  )
}

export const BoxInputElement = forwardRef(BoxInputElementInner)
