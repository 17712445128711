import { ReactNode, Suspense, useEffect, useState } from 'react'

import { useAuthenticationContext } from '@northvolt/cloud-auth'
import { config } from '@northvolt/cloud-config'
import { ErrorBoundary } from '@northvolt/cloud-error-handling'
import { GqlProvider } from '@northvolt/gql'
import { useStorageState } from '@northvolt/store'

import { Jumbotron } from './components/Jumbotron'
import { Loader } from './components/Loader'
import { RecentlyViewed } from './components/RecentlyViewed'
import { SystemList } from './components/SystemList'
import { SystemType } from './components/types'

const localStorageKey = 'inventory-app-system-type'

export function App() {
  const { getToken } = useAuthenticationContext()
  const [systemType, setSystemType] = useStorageState<SystemType>(localStorageKey, 'all')
  const [search, setSearch] = useState<string>('')
  const [debouncedSearch, setDebouncedSearch] = useState<string>('')

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedSearch(search)
    }, 500)
    return () => {
      clearTimeout(timeout)
    }
  }, [search])

  return (
    <GqlProvider apiUrl={config.REACT_APP_GRAPHQL_API} getToken={getToken}>
      <Jumbotron
        search={search}
        setSearch={setSearch}
        setSystemType={setSystemType}
        systemType={systemType}
      />
      <div className="mb-8">
        <ErrorBoundary title="Could not load Recently Viewed">
          <Suspense fallback={<Loader />}>
            <RecentlyViewed type={systemType} />
          </Suspense>
        </ErrorBoundary>
      </div>

      <ErrorBoundary title="Could not load System List">
        <Suspense fallback={<Loader />}>
          <HideList debouncedSearch={debouncedSearch} search={search}>
            <SystemList search={debouncedSearch} systemType={systemType} />
          </HideList>
        </Suspense>
      </ErrorBoundary>
    </GqlProvider>
  )
}

function HideList({
  search,
  debouncedSearch,
  children,
}: {
  search: string
  debouncedSearch: string
  children: ReactNode
}) {
  if (search !== debouncedSearch) {
    // if the search doesn't match the debouncedSearch we want to hide the SystemList and
    // show the <Loader />, this triggers the Suspense tag above it in the tree
    throw new Promise(() => {
      //
    })
  }
  return <>{children}</>
}
