import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { useEffect } from 'react'

import { toastError, toastSuccess } from '@northvolt/cloud-error-handling'
import {
  Button,
  Checkbox,
  Dropdown,
  FormError,
  FormLabel,
  Input,
  UseFormFieldProps,
  useForm,
} from '@northvolt/form'
import {
  CreateSecureElementMutation,
  CreateSecureElementMutationVariables,
  gql,
  useGqlMutation,
} from '@northvolt/gql'

import { useEntityRelationships } from './useEntityRelationships'

// also used in CreateCore
export const createSecureElementMutation = gql`
  mutation createSecureElement($fingerprint: String!, $hardwareSe: Boolean!, $tenant: String!) {
    addPublicKeyFingerprint(fingerprint: $fingerprint, hardwareSe: $hardwareSe, tenant: $tenant) {
      success
    }
  }
`

// also used in CreateCore
export function SecureElementInput({
  fingerprint,
  hardwareSe,
  required,
}: {
  fingerprint: UseFormFieldProps<string>
  hardwareSe: UseFormFieldProps<boolean>
  required: boolean
}) {
  // TODO adapt <Checkbox> to work like our other form components
  // so it can accept UseFormFieldProps<boolean> directly
  return (
    <>
      <Input
        className="mb-2"
        label="Secure Element Fingerprint"
        required={required}
        tabIndex={2}
        {...fingerprint}
      />
      <div className="flex flex-row items-center -ml-2">
        <Checkbox
          className="mb-2"
          id="hardwareSe"
          onClick={() => {
            hardwareSe.onChange?.(!hardwareSe.value)
          }}
          state={hardwareSe.value ?? false}
        />
        <FormLabel as="label" label="Hardware Secure Element" required={required} size="medium" />
      </div>
      <FormError error={hardwareSe.error} size="medium" />
    </>
  )
}

type CreateSecureElementFingerprintData = {
  fingerprint: string
  hardwareSe: boolean
  tenant: null | {
    label: string
    value: string
  }
}

export function CreateSecureElementFingerprint({ onSuccess }: { onSuccess: () => void }) {
  const { mutation, result } = useGqlMutation<
    CreateSecureElementMutation,
    CreateSecureElementMutationVariables
  >(createSecureElementMutation)
  const { tenants } = useEntityRelationships('core')

  useEffect(() => {
    if (result?.state === 'success') {
      toastSuccess('Secure Element Fingerprint created', null)
      onSuccess()
    } else if (result?.state === 'error') {
      toastError('Error creating Secure Element Fingerprint', result.errors)
    }
  }, [result, onSuccess])

  const props = useForm<CreateSecureElementFingerprintData>({
    initialValues: {
      fingerprint: '',
      hardwareSe: true,
      tenant: null,
    },
    onSubmit: async (formData) => {
      if (document.activeElement && document.activeElement.tagName === 'BUTTON') {
        await mutation({
          fingerprint: formData.fingerprint,
          hardwareSe: formData.hardwareSe,
          tenant: formData.tenant?.value as string,
        })
      }
    },
    onValidate: (values) => {
      const errors: Partial<{ [key in keyof CreateSecureElementMutationVariables]: string }> = {}
      if (values.fingerprint.length === 0) {
        errors.fingerprint = 'Please enter a fingerprint value.'
      } else if (!/^MD5(:[0-9a-fA-F]{2}){16}$/.test(values.fingerprint)) {
        errors.fingerprint = 'Fingerprint should have the format MD5:01:23:45:67:89:ab:cd:…:ef.'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a Tenant.'
      }
      return errors
    },
  })

  const { getFieldPropsByName, onSubmit, ...state } = props
  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <div className="mb-2 flex md:justify-end flex-col">
          <SecureElementInput
            fingerprint={getFieldPropsByName('fingerprint')}
            hardwareSe={getFieldPropsByName('hardwareSe')}
            required={true}
          />
          <div className="mb-2">
            <Dropdown
              label="Tenant"
              list={tenants}
              tabIndex={3}
              {...getFieldPropsByName('tenant')}
            />
          </div>
          <div className="mb-2 flex md:justify-end">
            <Button
              className="w-full md:w-auto"
              disabled={state.formHasErrors || result?.state === 'loading' || state.isSubmitting}
              prefix={faSave}
              style="primary"
              type="submit"
            >
              Create Fingerprint
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
