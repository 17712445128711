import * as Sentry from '@sentry/react'

import { config } from '@northvolt/cloud-config'

import { NvError } from './NvError'

type ProjectType = 'cb-cloud-ui' | 'cb-local-ui' | 'cb-service-app'
const sentryDsn: { [key in ProjectType]: string } = {
  'cb-cloud-ui':
    'https://dd17a2c8158644c493eeaa242153dd2c@o292401.ingest.sentry.io/4505006142324736',
  'cb-local-ui':
    'https://d6a1fa4cd1544579b39ca12e6025de4d@o292401.ingest.sentry.io/4505006143897600',
  'cb-service-app':
    'https://767be85cf3584086b17cde86d916696c@o292401.ingest.sentry.io/4505006145404928',
}

export function captureException(error: unknown) {
  Sentry.captureException(error)
}

export function initErrorReporting({
  projectName,
  projectType,
  enableTracing = true,
}: {
  projectName: string
  projectType: ProjectType
  enableTracing?: boolean
}) {
  Sentry.init({
    dsn: sentryDsn[projectType],
    integrations: enableTracing ? [Sentry.browserTracingIntegration()] : [],
    // collects errors into indexedDB and report them when connectivity is restored. See:
    // https://docs.sentry.io/platforms/javascript/configuration/transports/#offline-caching
    transport:
      projectType === 'cb-local-ui' || projectType === 'cb-service-app'
        ? Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport)
        : undefined,
    tracesSampleRate: enableTracing ? 1.0 : undefined,

    environment: config.REACT_APP_ENVIRONMENT,
    // releaseName MUST match value used in /ui/create-sentry-release.js
    release: `${projectName}-${config.REACT_APP_VERSION}`,
    beforeSend(event, { originalException }) {
      if (config.REACT_APP_SENTRY_REPORTING === false) {
        // do not report errors to sentry
        return null
      }

      if (originalException instanceof NvError) {
        if (
          originalException.shouldReportError === false ||
          (typeof originalException.shouldReportError === 'function' &&
            originalException.shouldReportError() === false)
        ) {
          return null
        }
        event.level = event.level ?? 'error'
        // extra ends up as "Additional Data" in the sentry UI issue
        event.extra = {
          ...event.extra,
          ...(originalException.getReportExtra?.() ?? {}),
          name: originalException.name,
          message: originalException.message,
        }
      } else {
        event.level = event.level ?? 'fatal'
      }

      return event
    },
  })
  Sentry.setContext('project', {
    projectName,
  })
}
