import { faSave } from '@fortawesome/pro-regular-svg-icons'
import { useEffect, useState } from 'react'

import { toastError, toastSuccess } from '@northvolt/cloud-error-handling'
import { Button, Dropdown, Input, InputMultiselect, UseFormErrors, useForm } from '@northvolt/form'
import {
  BatterySystemGeneration,
  CreatePackMutation,
  CreatePackMutationVariables,
  gql,
  useGqlMutation,
} from '@northvolt/gql'

import { getSystemUrl } from '../../utils/getSystemUrl'

import { useEntityRelationships } from './useEntityRelationships'

const createPackMutation = gql`
  mutation createPack($id: ID!, $pack: CreatePackInput!, $tags: [String!]!) {
    createPack(id: $id, packDetails: $pack) {
      success
    }

    setPackTags(id: $id, tags: $tags) {
      success
    }
  }
`

type CreatePackData = {
  id: string
  displayName: string
  tenant: null | {
    label: string
    value: string
  }
  generation: null | {
    label: string
    value: BatterySystemGeneration
  }
  product: null | {
    label: string
    value: string
  }
  tags: string[]
}

export function CreatePack({ onSuccess }: { onSuccess: () => void }) {
  const [packId, setPackId] = useState<string>('')
  const { mutation, result } = useGqlMutation<CreatePackMutation, CreatePackMutationVariables>(
    createPackMutation,
  )
  const { tenants, generations, products, tags } = useEntityRelationships('pack')

  useEffect(() => {
    if (result?.state === 'success') {
      toastSuccess(
        'Pack created successfully',
        <div>
          <a
            className="pt-2 h-auto transition ease-in duration-200 border-b-2 hover:text-powerGreen hover:border-transparent border-powerGreen"
            href={getSystemUrl(packId)}
            target="__blank"
          >
            View in detail page
          </a>
        </div>,
      )
      onSuccess()
    } else if (result?.state === 'error') {
      toastError('Error creating Pack', result.errors)
    }
  }, [result, onSuccess, packId])

  const { getFieldPropsByName, onSubmit, ...state } = useForm<CreatePackData>({
    initialValues: {
      id: '',
      displayName: '',
      tenant:
        tenants.find((v) => {
          return v.value === 'northvolt'
        }) ?? null,
      generation:
        generations.find((v) => {
          return v.value === BatterySystemGeneration.D
        }) ?? null,
      product: null,
      tags: [],
    },
    onSubmit: async (formData) => {
      if (document.activeElement && document.activeElement.tagName === 'BUTTON') {
        setPackId(formData.id)
        await mutation({
          id: (formData.id as string).trim(),
          pack: {
            displayName: formData.displayName,
            tenant: formData.tenant?.value as string,
            generation: formData.generation?.value as BatterySystemGeneration,
            product: formData.product?.value as string,
          },
          tags: formData.tags as string[],
        })
      }
    },
    onValidate: (values) => {
      const errors: UseFormErrors<CreatePackData> = {}
      if (values.id.length === 0) {
        errors.id = 'Please enter an ID'
      }
      if (values.id.length > 16) {
        errors.id = 'ID cannot be longer than 16 characters'
      }
      if (values.displayName.length === 0) {
        errors.displayName = 'Please enter a display name'
      }
      if (values.generation == null) {
        errors.generation = 'Please select a generation'
      }
      if (values.tenant == null) {
        errors.tenant = 'Please select a tenant'
      }
      if (values.product == null) {
        errors.product = 'Please select a product'
      }
      return errors
    },
  })

  return (
    <div className="w-full">
      <form onSubmit={onSubmit}>
        <Input className="mb-2" label="ID" required tabIndex={1} {...getFieldPropsByName('id')} />
        <Input
          className="mb-2"
          label="Display name"
          required
          tabIndex={2}
          {...getFieldPropsByName('displayName')}
        />
        <div className="mb-2">
          <Dropdown label="Tenant" list={tenants} tabIndex={3} {...getFieldPropsByName('tenant')} />
        </div>
        <div className="mb-2">
          <Dropdown
            label="Generation"
            list={generations}
            placeholder="Generation"
            tabIndex={4}
            {...getFieldPropsByName('generation')}
          />
        </div>
        <div className="mb-2">
          <Dropdown
            label="Product"
            list={products}
            placeholder="Product"
            tabIndex={4}
            {...getFieldPropsByName('product')}
          />
        </div>
        <InputMultiselect
          autocomplete={tags}
          className="mb-2"
          label="Tags"
          tabIndex={6}
          {...getFieldPropsByName('tags')}
        />
        <div className="mb-2 flex md:justify-end">
          <Button
            className="w-full md:w-auto"
            disabled={state.formHasErrors || result?.state === 'loading' || state.isSubmitting}
            prefix={faSave}
            style="primary"
            type="submit"
          >
            Create Pack
          </Button>
        </div>
      </form>
    </div>
  )
}
