export function getTestId(testId?: string | boolean) {
  /**
   * If a testId is provided, we return an object with the data-testid attribute.
   * This is done even in production builds, to be able to test and automate production builds.
   */
  if (testId != null) {
    return { 'data-testid': testId }
  }

  return null
}
