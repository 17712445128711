import {
  faBox,
  faBoxes,
  faChevronDown,
  faFingerprint,
  faNetworkWired,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Suspense, useCallback, useMemo, useState } from 'react'

import { RolesEnum, useAuthenticationContext } from '@northvolt/cloud-auth'
import { Button, Menu, MenuItemProps } from '@northvolt/form'
import { BaseModal } from '@northvolt/modal'

import { Loader } from '../Loader'

import { CreateCore } from './CreateCore'
import { CreatePack } from './CreatePack'
import { CreateSecureElementFingerprint } from './CreateSecureElementFingerprint'
import { CreateVoltpackMobileHub } from './CreateVoltpackMobileHub'
import { useRevalidateSystemsQuery } from './useRevalidateSystemsQuery'

type CreateOption =
  | 'createSecureElementFingerprint'
  | 'createPack'
  | 'createCore'
  | 'createVoltpackMobileHub'

const getModalHeader = (optionClicked: CreateOption) => {
  switch (optionClicked) {
    case 'createSecureElementFingerprint':
      return 'Create Secure Element Fingerprint'
    case 'createCore':
      return 'Create Core'
    case 'createPack':
      return 'Create Pack'
    case 'createVoltpackMobileHub':
      return 'Create Voltpack Mobile Hub'
  }
}

export function Provisioning({ tabIndex }: { tabIndex?: number }) {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [optionClicked, setOptionClicked] = useState<CreateOption>('createCore')

  const revalidateSystemsQuery = useRevalidateSystemsQuery()
  const closeModal = useCallback(() => {
    revalidateSystemsQuery()
    setIsDialogOpen(false)
  }, [revalidateSystemsQuery])

  const { user } = useAuthenticationContext()

  const list = useMemo(() => {
    const menuClickHandler = (option: CreateOption) => () => {
      setOptionClicked(option)
      setIsDialogOpen(true)
    }
    const menuItems = {
      createCore: {
        onClick: menuClickHandler('createCore'),
        children: (
          <>
            <FontAwesomeIcon className="-ml-0.5 mr-3 text-gray-700" icon={faBoxes} />
            Core
          </>
        ),
      },
      createPack: {
        children: (
          <>
            <FontAwesomeIcon className="mr-3 text-gray-700" icon={faBox} />
            Pack
          </>
        ),
        onClick: menuClickHandler('createPack'),
      },
      createVoltpackMobileHub: {
        children: (
          <>
            <FontAwesomeIcon className="-ml-1 mr-3 text-gray-700" icon={faNetworkWired} />
            Voltpack Mobile Hub
          </>
        ),
        onClick: menuClickHandler('createVoltpackMobileHub'),
      },
      createHardwareFingerprint: {
        children: (
          <>
            <FontAwesomeIcon className="mr-4 text-gray-700" icon={faFingerprint} />
            Secure Element Fingerprint
          </>
        ),
        onClick: menuClickHandler('createSecureElementFingerprint'),
      },
    }

    let list: MenuItemProps[] = []
    if (user.tenant === 'northvolt') {
      list = Object.values(menuItems)
    } else if (
      user.roles.some(
        (role: RolesEnum) =>
          role === RolesEnum.BATTERY_DIAGNOSTICS_ADMIN ||
          role === RolesEnum.BATTERY_DIAGNOSTICS_PRODUCTION ||
          role === RolesEnum.BATTERY_DIAGNOSTICS_WRITER ||
          role === RolesEnum.ROOT,
      )
    ) {
      list = [menuItems.createPack]
    }
    return list
  }, [user])

  const menuButton = (
    <Button
      className="w-full"
      disableActiveStyle
      prefix={faPlus}
      size="large"
      style="primary"
      // use the same size as the dropdown icon
      suffix={{ icon: faChevronDown, className: 'h-3.5 w-3.5', customSize: true }}
      textAlignment="left"
    >
      Provisioning
    </Button>
  )

  return (
    <>
      <div>
        <Menu alignMenu="right" button={menuButton} list={list} tabIndex={tabIndex} />
      </div>

      <BaseModal
        header={getModalHeader(optionClicked)}
        onClose={() => {
          setIsDialogOpen(false)
        }}
        visible={isDialogOpen}
      >
        <div className="flex flex-col md:flex-row gap-2">
          <div className="flex w-full">
            <Suspense fallback={<Loader />}>
              {optionClicked === 'createCore' ? <CreateCore onSuccess={closeModal} /> : null}

              {optionClicked === 'createPack' ? <CreatePack onSuccess={closeModal} /> : null}

              {optionClicked === 'createVoltpackMobileHub' ? (
                <CreateVoltpackMobileHub onSuccess={closeModal} />
              ) : null}

              {optionClicked === 'createSecureElementFingerprint' ? (
                <CreateSecureElementFingerprint onSuccess={closeModal} />
              ) : null}
            </Suspense>
          </div>
        </div>
      </BaseModal>
    </>
  )
}
